body {
  margin: 0;
  font-family: 'Satoshi', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
}

.\!text-h1 {
  /* used to mimic h1 appearance on non-h1 elements */
  font-size: 2.125rem !important;
  line-height: 2.5rem !important;
}